/*DESKTOP*/
@media screen and (min-width:1024px) {
    .container{
        width: 100%;
        overflow: hidden;
        display: flex;
        place-content: center;
    }

    .container > div {
        width: 95%;
        font-size: 17px;
    }

    .datachart{
        width: 99%;
    }
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px){
    .container{
        width: 100%;
        overflow: hidden;
        display: flex;
        place-content: center;
    }

    .container > div {
        width: 90%;
        font-size: 17px;
    }

    .datachart{
        width: 99%;
    }
}

/*MOBILE*/
@media screen and (max-width:767px){
    .container{
        width: 100%;
        overflow: hidden;
        display: flex;
        place-content: center;
    }

    .container > div {
        width: 95%;
        font-size: 17px;
    }

    .datachart{
        margin-top: 50px;
        height: 600px;
        width: 99%;
    }
}