/*DESKTOP*/
@media screen and (min-width:1024px) {
    .container {
        height: 90px;
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: 29% 29% 29% 29%;
    }
}


.datapicker {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    width: 120%;
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px) {
    .container {
        height: 90px;
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: 29% 29% 29% 29%;
        width: 100%;
    }
}

/*MOBILE*/
@media screen and (max-width:767px) {
    .container {
        height: 60px;
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: 29% 29% 29% 29%;
    }
}