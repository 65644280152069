/*DESKTOP*/
@media screen and (min-width:1024px) {
    .container {
        display: flex; 
        justify-content: center; 
        align-items: center;
        height: 90vh;
    }
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px){
    .container {
        display: flex; 
        justify-content: center; 
        align-items: center;
        height: 90vh;
    }
}

/*MOBILE*/
@media screen and (max-width:767px){
    .container {
        display: flex; 
        justify-content: center; 
        align-items: center;
        height: 90vh;
    } 
}