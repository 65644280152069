/*DESKTOP*/
@media screen and (min-width:1024px) {
    /* .body{
        position: fixed;
        width: 100%;
        margin-top: 65px;
    } */

    .withoutMenu{
        display: grid;
        grid-template-columns: 0 100%;
        transition: 0.5s ;
    }
    .withMenu{
        display: grid;
        grid-template-columns: 20% 80%;
    }  
    
    /* .withoutMenu{
        min-height: 780px;
        overflow-y: hidden;
    }   */
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px){
    /* .body{
         margin-top: 65px; 
        max-height: 700px;
        overflow-y: hidden;
    } */

    .withoutMenu{
        display: grid;
        grid-template-columns: 0 100%;
        transition: 1.0s ;
    }
    .withMenu{
        display: grid;
        grid-template-columns: 25% 75%;
    }  
    
    /* .withoutMenu{
        min-height: 700px;
    }  */
}

/*MOBILE*/
@media screen and (max-width:767px){
    /* .body{
        margin-top: 12.5%;
        max-height:650px;
        min-height: 600px;
        overflow-y: hidden;
    } */

    .body{
        height: 100vh;
    }
    .withoutMenu{
        display: grid;
        grid-template-columns: 0 100%;
        transition: 1.0s ;
    }
    /* .withMenu{
      display: grid; 
      grid-template-columns: 0 100%;
    }   */
    
    /* .withoutMenu{
        min-height: 700px;
    }  */
}