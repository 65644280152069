/*DESKTOP*/
@media screen and (min-width:1024px) {
    .sign{
        display: flex;
        place-content: center;
        margin-top: 25px;
    }

    .paper{
        width: 50%;
        height: 380px;
        padding: 2.5% 5% 0 5%;
        margin-top: 10px;
        font-size: 18px;
    }
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px){
    .sign{
    display: flex;
    place-content: center;
    margin-top: 25px;
    }

   .paper{
    width: 70%;
    height: 350px;
    padding: 3% 5%;
    margin-top: 10px;
    font-size: 18px;
   }
}

/*MOBILE*/
@media screen and (max-width:767px){
    .sign{
        display: flex;
        place-content: center;
        margin-top: 25px;
    }

   .paper{
    width: 80%;
    height: 350px;
    padding: 3% 5%;
    margin-top: 40px;
    font-size: 18px;
   }  
}