.logContainer {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8e9f8;
  height: 100vh;
}

.screen-1 {
  background: #f1f7fe;
  padding: 2em;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 0 2em #e6e9f9;
  gap: 2em;
}

.screen-1 .logo {
  margin-top: -3em;
}

.screen-1 .email {
  background: rgba(255, 255, 255, 0.800);
  box-shadow: 0 0 2em #e6e9f9;
  padding: 0.7em;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  border-radius: 5px;
  color: #4d4d4d;
  margin-top: -3em;
}

.screen-1 .email input {
  outline: none;
  border: none;
  margin: 5px 0 0 30px;
  width: 85%;
}

.screen-1 .email input::-moz-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .email input:-ms-input-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 input::placeholder {
  color: rgba(0, 0, 0, 0.584);
  font-size: 1.1em;
}

.screen-1 .email ion-icon {
  color: #4d4d4d;
  margin-bottom: -0.2em;
}

.screen-1 .password {
  background: rgba(255, 255, 255, 0.800);
  box-shadow: 0 0 2em #e6e9f9;
  padding: 0.7em;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  border-radius: 5px;
  color: #4d4d4d;
}

.screen-1 .password input {
  outline: none;
  border: none;
  margin: 5px 0 0 30px;
  width: 85%;
}

.screen-1 .password input::-moz-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .password input:-ms-input-placeholder {
  color: black;
  font-size: 0.9em;
}

.screen-1 .password .show-hide {
  margin-right: -5em;
}

.screen-1 .login {
  padding: 1em;
  background: #3e4684;
  color: white;
  border: none;
  border-radius: 30px;
  font-weight: 600;
}

.screen-1 .footer {
  display: flex;
  font-size: 0.7em;
  color: #5e5e5e;
  gap: 14em;
  min-width: 300px;
}

.screen-1 .footer span {
  cursor: pointer;
  margin-left: 5px;
}

button {
  cursor: pointer;
}

@keyframes example {
  0% {
    transform: translateX(2px)
  }

  25% {
    transform: translateX(5px)
  }

  50% {
    transform: translateX(-5px)
  }

  75% {
    transform: translateX(5px)
  }

  100% {
    transform: translateX(-3px)
  }
}

h1 {
  text-align: center;
  font-size: 15px;
  color: #D44D5C;
  margin-top: -67px;
  margin-bottom: 30px;
  font-weight: 400;
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}