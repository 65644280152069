/*DESKTOP*/
@media screen and (min-width:1024px) {
    .container{
        display: flex;
        justify-content: center;
        text-transform: capitalize;
    }

    .container > div {
        width: 100%;
        margin-top: 15px;
    }

   .options{
        display: flex;
        place-content: center;
   }

   .form{
        display: flex;
        font-size: 18px;
        margin-top: 20px;
        place-content: center;
   }

   .paper{
       width: 50%;
       padding: 2% 5%;
   }
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px){
    .container{
        text-transform: capitalize;
    }
    
    .container > div {
        width: 100%;
        margin-top: 15px;
    }

    .options{
        display: flex;
        place-content: center;
    }
 
    .form{
        display: flex;
        font-size: 18px;
        margin-top: 15px;
        place-content: center;
    }
 
    .paper{
        width: 65%;
        padding: 2% 5%;
    }
}

/*MOBILE*/
@media screen and (max-width:767px) {
    .container {
        display: flex;
        place-content: center;
        text-transform: capitalize;
    }

    .container > div {
        width: 100%;
        margin-top: 15px;
    }

    .options{
        display: flex;
        place-content: center;
    }
 
    .form{
        margin-top: 20px;
        font-size: 18px;
    }
 
    .paper{
        margin: auto;
        padding: 5%;
        max-width: 370px;
    }
}