.container{
   width: 100%;
   display: flex;
   place-content: center;
}

.container > div{
    width: 60%;
    margin-top: 50px;
}

.paper {
    text-align: center;
}

.buttons{
    display: flex;
    margin-left: 60px;
}

/*DESKTOP*/
@media screen and (min-width:1024px) {
    .paper {
        height: 500px
    }

    .privs{
        margin-bottom: 20px;
    }
 }

 /*TABLET*/
 @media screen and (min-width:767px) and (max-width:1024px){
   .paper {
        height: 440px
    }
    
    .privs{
        margin-bottom: 15px;
    }
}

 /*MOBILE*/
 @media screen and (max-width:767px){
     .container > div{
         width: 95%;
     }
 
     .paper {
         height: 420px;
     }
     
     .privs{
         margin-bottom: 25px;
     }
}