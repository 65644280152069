.container {
    position: absolute;
    margin: auto;
    bottom: 2px;
    height: fit-content;
    right: 10px;
    width: 150px;
    text-align: center;
}

.tooltip{
    float: right !important;
    margin-top: 15px;
}

.setBtn{
    float: right !important;
}