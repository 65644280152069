.login{
   width: 100%;
   height: 100vh;
   display: flex;
   place-content: center;
   background-color: #bdbdbd;
}

.card{
 margin-top: 4%;
 width: 30%;
 height: 450px;
 text-align: center;
 border-radius: 50%;
}

.text{
   margin-top: 50px;
}

.inputs{
 padding:8% 8% 8% 3%;
 row-gap: 15px;
 display: grid;
}

.error{
   text-align: right;
   margin-top: -40px;
   margin-bottom: 30px;
   color: red;
   width: 92%;
}

.btns{
   display:flex;
   flex-wrap: wrap;
   justify-content: space-around;
}

@media screen and (max-width:767px) {
   .card{
      width : 80%;
      margin: auto;
   }
}