body,
html {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: Calibri, sans-serif !important;
}

html {
  white-space: nowrap;
}

body {
  overflow-x: hidden;
  display: inline-block;
}

tr,
td {
  height: 10px !important;
}

body::-webkit-scrollbar {
  width: 0.4em !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(250, 244, 244, 0.3) !important;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
}