/*DESKTOP*/
@media screen and (min-width:1024px) {
    .btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 5%;
    }
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px) {
    .btn {
        display: flex;
        place-content: center;
        margin-top: 5%;
    }
}

/*MOBILE*/
@media screen and (max-width:767px) {
    .btn {
        display: flex;
        place-content: center;
        margin-top: 5%;
    }
}