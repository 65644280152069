/*DESKTOP*/
@media screen and (min-width:1024px) {
   .container{
       display: flex;
       place-content: center;
       text-transform: capitalize;
   }

   .container > div {
       width: 95%;
   }

   .options {
       display: flex;
       padding-top: 1%;
       place-content: center;
   }
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px) {
   .container{
       display: flex;
       place-content: center;
       text-transform: capitalize;
   }

   .container > div {
       width: 90%;
   }

   .options {
       display: flex;
       padding-top: 1%;
       place-content: center;
   }
}

/*MOBILE*/
@media screen and (max-width:767px) {
   .container{
       display: flex;
       place-content: center;
       text-transform: capitalize;
   }

   .container > div {
       width: 90%;
   }

   .options {
       display: flex;
       padding-top: 1%;
       place-content: center;
   }
}