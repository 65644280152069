.container {
    background-color: transparent;
    position: absolute;
    width: 100%;
    margin: auto;
}

.ul {
    background-color: transparent !important;
    list-style-type: none;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}


.states{
    display: grid;
    grid-template-columns: 20% 80%;
    background-color: transparent !important;
}

.ul li {
    align-items: center;
    display: flex;
}

.container {
    top: 75px;
    height: fit-content;
    width: 180px;
    right: 5px;
    margin: auto;
}

.ul li {
    font-size: 15px;
    width: 80px;
}

ul {
    padding-left: 12px !important;
}

.tooltip, .legendaBtn{
    margin:10px 10px 0 0;
    float: right !important;
}

/*DESKTOP */
@media screen and (min-width:1024px) {
    
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px){
    
}

/*MOBILE*/
@media screen and (max-width:767px){
    
}