/*DESKTOP & TABLET*/
@media screen and (min-width:767px) {
    .ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    .states {
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .paper {
        margin-top: 100px;
        height: 40%;
        display: flex;
        flex-direction: column;
    }


    .legenda {
        padding-right: 15px;
    }

    .button {
        text-transform: capitalize !important;
        margin-top: 10px !important;
    }
}

.antpopover {
    z-index: 30000 !important;
}

/*MOBILE*/
@media screen and (max-width:767px) {
    .ul {
        font-size: 12px;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .ul li {
        float: left;
    }

    .paper {
        margin-top: 80px;
        padding: 5% 1%;
        height: 130px;
        margin-left: 0%;
    }

    .states {
        display: grid;
        grid-template-columns: 20% 80%;
    }

    .ul li {
        float: left;
    }

    .button {
        text-transform: capitalize !important;
        margin-top: 30% !important;
    }
}