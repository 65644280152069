/*DESKTOP*/
@media screen and (min-width:1024px) {
    .paper{
        max-width: 300px; 
        height: 300px; 
        overflow: auto; 
        margin-top: 15px;
    }
  
    .gridCont{
        justify-content:center; 
        align-items:center;
    }

    .buttons{
       margin-top: 10px !important;
    }
}

/*TABLET*/
@media screen and (min-width:767px) and (max-width:1024px){
    .paper{
        max-width: 250px; 
        height: 250px; 
        overflow: auto;
        margin-top: 15px; 
    }

    .gridCont{
        justify-content:center; 
        align-items:center;
        display: flex;
    }

    .buttons{
       margin-top: 8px !important;
    }
}

/*MOBILE*/
@media screen and (max-width:767px){
    .paper{
        max-width: 250px; 
        min-width: 155px;
        height: 250px; 
        overflow: auto; 
        margin :auto;
        margin-top: 15px;
    }

    .gridCont{
        justify-content:center; 
        align-items:center;
        display: flex;
    }

    .buttons{
       margin-top: 5px !important;
       width: 8% !important;
    }
}